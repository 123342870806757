// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-aerospace-and-defence-tsx": () => import("./../../../src/pages/aerospace-and-defence.tsx" /* webpackChunkName: "component---src-pages-aerospace-and-defence-tsx" */),
  "component---src-pages-alarms-tsx": () => import("./../../../src/pages/alarms.tsx" /* webpackChunkName: "component---src-pages-alarms-tsx" */),
  "component---src-pages-beaumont-seymour-tsx": () => import("./../../../src/pages/beaumont-seymour.tsx" /* webpackChunkName: "component---src-pages-beaumont-seymour-tsx" */),
  "component---src-pages-caps-cases-tsx": () => import("./../../../src/pages/caps-cases.tsx" /* webpackChunkName: "component---src-pages-caps-cases-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-case-study-template-tsx": () => import("./../../../src/pages/case-study-template.tsx" /* webpackChunkName: "component---src-pages-case-study-template-tsx" */),
  "component---src-pages-chemicals-paints-adhesive-tsx": () => import("./../../../src/pages/chemicals-paints-adhesive.tsx" /* webpackChunkName: "component---src-pages-chemicals-paints-adhesive-tsx" */),
  "component---src-pages-claims-process-tsx": () => import("./../../../src/pages/claims-process.tsx" /* webpackChunkName: "component---src-pages-claims-process-tsx" */),
  "component---src-pages-confirmation-tsx": () => import("./../../../src/pages/confirmation.tsx" /* webpackChunkName: "component---src-pages-confirmation-tsx" */),
  "component---src-pages-construction-and-building-material-tsx": () => import("./../../../src/pages/construction-and-building-material.tsx" /* webpackChunkName: "component---src-pages-construction-and-building-material-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-eccc-tsx": () => import("./../../../src/pages/eccc.tsx" /* webpackChunkName: "component---src-pages-eccc-tsx" */),
  "component---src-pages-electronics-and-electrical-tsx": () => import("./../../../src/pages/electronics-and-electrical.tsx" /* webpackChunkName: "component---src-pages-electronics-and-electrical-tsx" */),
  "component---src-pages-engineering-and-machinery-design-tsx": () => import("./../../../src/pages/engineering-and-machinery-design.tsx" /* webpackChunkName: "component---src-pages-engineering-and-machinery-design-tsx" */),
  "component---src-pages-hilton-food-tsx": () => import("./../../../src/pages/hilton-food.tsx" /* webpackChunkName: "component---src-pages-hilton-food-tsx" */),
  "component---src-pages-hut-architecture-tsx": () => import("./../../../src/pages/hut-architecture.tsx" /* webpackChunkName: "component---src-pages-hut-architecture-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industry-sector-tsx": () => import("./../../../src/pages/industry-sector.tsx" /* webpackChunkName: "component---src-pages-industry-sector-tsx" */),
  "component---src-pages-intellectual-property-services-tsx": () => import("./../../../src/pages/intellectual-property-services.tsx" /* webpackChunkName: "component---src-pages-intellectual-property-services-tsx" */),
  "component---src-pages-intellectual-property-tsx": () => import("./../../../src/pages/intellectual-property.tsx" /* webpackChunkName: "component---src-pages-intellectual-property-tsx" */),
  "component---src-pages-materials-finishes-and-construction-tsx": () => import("./../../../src/pages/materials-finishes-and-construction.tsx" /* webpackChunkName: "component---src-pages-materials-finishes-and-construction-tsx" */),
  "component---src-pages-meet-the-team-tsx": () => import("./../../../src/pages/meet-the-team.tsx" /* webpackChunkName: "component---src-pages-meet-the-team-tsx" */),
  "component---src-pages-mlm-group-tsx": () => import("./../../../src/pages/mlm-group.tsx" /* webpackChunkName: "component---src-pages-mlm-group-tsx" */),
  "component---src-pages-modus-engineering-services-tsx": () => import("./../../../src/pages/modus-engineering-services.tsx" /* webpackChunkName: "component---src-pages-modus-engineering-services-tsx" */),
  "component---src-pages-patent-box-tsx": () => import("./../../../src/pages/patent-box.tsx" /* webpackChunkName: "component---src-pages-patent-box-tsx" */),
  "component---src-pages-pharmaceuticals-biotechnology-and-medical-science-tsx": () => import("./../../../src/pages/pharmaceuticals-biotechnology-and-medical-science.tsx" /* webpackChunkName: "component---src-pages-pharmaceuticals-biotechnology-and-medical-science-tsx" */),
  "component---src-pages-pps-commercials-tsx": () => import("./../../../src/pages/pps-commercials.tsx" /* webpackChunkName: "component---src-pages-pps-commercials-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-rise-scaffold-tsx": () => import("./../../../src/pages/rise-scaffold.tsx" /* webpackChunkName: "component---src-pages-rise-scaffold-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-skyline-green-interiors-tsx": () => import("./../../../src/pages/skyline-green-interiors.tsx" /* webpackChunkName: "component---src-pages-skyline-green-interiors-tsx" */),
  "component---src-pages-sponsorships-tsx": () => import("./../../../src/pages/sponsorships.tsx" /* webpackChunkName: "component---src-pages-sponsorships-tsx" */),
  "component---src-pages-sustainability-tsx": () => import("./../../../src/pages/sustainability.tsx" /* webpackChunkName: "component---src-pages-sustainability-tsx" */),
  "component---src-pages-tax-credit-tsx": () => import("./../../../src/pages/tax-credit.tsx" /* webpackChunkName: "component---src-pages-tax-credit-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-testing-tsx": () => import("./../../../src/pages/testing.tsx" /* webpackChunkName: "component---src-pages-testing-tsx" */),
  "component---src-pages-tom-gamble-sponsorship-tsx": () => import("./../../../src/pages/tom-gamble-sponsorship.tsx" /* webpackChunkName: "component---src-pages-tom-gamble-sponsorship-tsx" */),
  "component---src-pages-wesley-james-ltd-tsx": () => import("./../../../src/pages/wesley-james-ltd.tsx" /* webpackChunkName: "component---src-pages-wesley-james-ltd-tsx" */),
  "component---src-pages-weston-homes-tsx": () => import("./../../../src/pages/weston-homes.tsx" /* webpackChunkName: "component---src-pages-weston-homes-tsx" */)
}

